import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;

	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 605.000000 1080.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">
					<path d="M2830 8314 c-105 -8 -328 -45 -451 -75 -287 -70 -610 -207 -851 -360
-265 -170 -570 -450 -745 -685 -357 -480 -537 -964 -584 -1574 -19 -252 11
-579 77 -845 133 -534 395 -994 778 -1366 67 -64 141 -134 166 -154 25 -20 55
-46 68 -56 104 -89 365 -253 507 -319 187 -88 212 -98 336 -140 328 -110 575
-150 929 -150 406 0 715 59 1085 208 285 114 613 320 820 512 28 26 69 64 93
85 55 49 252 278 323 375 278 381 465 859 524 1337 22 174 17 614 -9 773 -136
839 -607 1559 -1308 2000 -337 211 -701 347 -1103 410 -127 20 -512 34 -655
24z m596 -94 c539 -72 952 -248 1374 -584 98 -78 360 -339 442 -441 317 -393
501 -807 580 -1310 30 -190 33 -662 5 -850 -32 -211 -101 -484 -152 -600 -8
-16 -26 -59 -40 -95 -68 -165 -197 -385 -328 -559 -136 -179 -356 -407 -501
-519 -34 -26 -68 -54 -77 -62 -9 -8 -50 -38 -90 -67 -458 -320 -989 -483
-1578 -483 -713 0 -1323 229 -1841 689 -532 473 -842 1043 -931 1708 -43 315
-27 747 37 1028 85 376 263 754 497 1055 133 171 351 395 487 500 25 19 47 38
50 41 15 19 201 145 295 200 319 187 635 295 1020 349 191 26 553 27 751 0z"/>
<path d="M2702 8154 c-125 -16 -291 -48 -387 -76 -38 -11 -90 -25 -115 -32
-77 -22 -271 -103 -365 -151 -162 -84 -364 -216 -469 -305 -229 -195 -403
-378 -541 -570 -258 -359 -418 -768 -474 -1210 -19 -145 -24 -425 -12 -592 27
-361 130 -729 288 -1027 42 -80 150 -260 163 -271 3 -3 21 -27 39 -55 19 -27
38 -52 41 -55 3 -3 21 -25 40 -50 96 -125 270 -301 420 -427 386 -322 800
-508 1315 -589 134 -21 515 -29 680 -15 378 33 762 150 1057 323 49 29 102 60
117 69 225 133 566 448 741 684 25 33 48 62 51 65 25 22 165 250 214 350 194
394 276 760 276 1225 0 230 -14 389 -51 571 -137 666 -506 1224 -1104 1669
-342 254 -795 425 -1258 475 -132 14 -539 10 -666 -6z m743 -88 c597 -90 1050
-321 1485 -756 336 -337 574 -748 676 -1170 111 -453 108 -933 -6 -1367 -118
-444 -371 -864 -720 -1196 -305 -290 -585 -461 -980 -600 -290 -102 -723 -149
-1080 -117 -272 25 -583 102 -785 195 -16 7 -57 25 -90 40 -121 53 -342 192
-475 297 -714 570 -1068 1355 -1012 2245 25 389 102 684 261 1002 217 432 585
828 1016 1091 306 187 697 313 1089 351 120 11 506 2 621 -15z"/>
<path d="M2890 8040 c-427 -24 -862 -166 -1205 -395 -450 -300 -800 -712 -992
-1165 -149 -354 -213 -742 -192 -1163 4 -92 11 -184 14 -205 92 -550 263 -928
592 -1310 126 -147 333 -339 453 -422 19 -13 41 -30 47 -36 7 -7 68 -46 136
-88 311 -191 639 -302 1022 -347 142 -16 484 -14 640 5 362 43 746 177 1010
353 225 149 303 212 480 388 357 356 601 799 694 1260 69 341 70 747 2 1091
-95 489 -341 931 -726 1308 -400 390 -840 614 -1379 701 -85 13 -404 37 -446
33 -8 -1 -76 -4 -150 -8z m7 -142 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4
-12 1 -19z m177 7 c-4 -8 -8 -15 -10 -15 -2 0 -4 7 -4 15 0 8 4 15 10 15 5 0
7 -7 4 -15z m176 0 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6
0 10 -7 10 -15z m-520 -15 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10
10 10 6 0 10 -4 10 -10z m310 -6 c0 -2 -9 -4 -21 -4 -11 0 -18 4 -14 10 5 8
35 3 35 -6z m78 4 c-2 -6 -10 -14 -16 -16 -7 -2 -10 2 -6 12 7 18 28 22 22 4z
m301 0 c0 -7 -4 -22 -9 -33 -6 -16 -8 -14 -7 13 0 17 4 32 9 32 4 0 8 -6 7
-12z m-564 -8 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4
11 -10z m90 0 c-3 -5 -11 -10 -16 -10 -6 0 -7 5 -4 10 3 6 11 10 16 10 6 0 7
-4 4 -10z m260 0 c3 -5 -1 -10 -9 -10 -9 0 -16 5 -16 10 0 6 4 10 9 10 6 0 13
-4 16 -10z m85 0 c0 -5 -8 -10 -17 -10 -15 0 -16 2 -3 10 19 12 20 12 20 0z
m-510 -10 c0 -5 -7 -10 -16 -10 -8 0 -12 5 -9 10 3 6 10 10 16 10 5 0 9 -4 9
-10z m598 -8 c2 -7 -2 -10 -12 -6 -9 3 -16 11 -16 16 0 13 23 5 28 -10z m-818
-17 c0 -18 7 -26 25 -31 14 -3 25 -11 25 -16 0 -5 -6 -7 -12 -4 -7 3 -21 9
-30 12 -10 4 -18 12 -18 19 0 8 -3 20 -6 29 -3 9 -1 16 5 16 6 0 11 -11 11
-25z m125 15 c3 -5 -1 -10 -9 -10 -9 0 -16 5 -16 10 0 6 4 10 9 10 6 0 13 -4
16 -10z m555 0 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11
-4 11 -10z m225 0 c-3 -5 -10 -10 -16 -10 -5 0 -9 5 -9 10 0 6 7 10 16 10 8 0
12 -4 9 -10z m130 0 c-3 -5 -10 -10 -16 -10 -5 0 -9 5 -9 10 0 6 7 10 16 10 8
0 12 -4 9 -10z m-991 -9 c3 -5 -1 -11 -9 -14 -9 -4 -12 -1 -8 9 6 16 10 17 17
5z m101 -26 c-14 -16 -28 -25 -31 -22 -4 3 7 17 22 31 37 31 42 26 9 -9z m160
9 c-9 -9 -19 -13 -23 -10 -3 4 0 11 8 16 24 15 33 11 15 -6z m80 6 c3 -5 2
-10 -4 -10 -5 0 -13 5 -16 10 -3 6 -2 10 4 10 5 0 13 -4 16 -10z m90 0 c-3 -5
-10 -10 -16 -10 -5 0 -9 5 -9 10 0 6 7 10 16 10 8 0 12 -4 9 -10z m80 0 c3 -5
2 -10 -4 -10 -5 0 -13 5 -16 10 -3 6 -2 10 4 10 5 0 13 -4 16 -10z m90 0 c-3
-5 -10 -10 -16 -10 -5 0 -9 5 -9 10 0 6 7 10 16 10 8 0 12 -4 9 -10z m-675
-16 c0 -2 -9 -4 -21 -4 -11 0 -18 4 -14 10 5 8 35 3 35 -6z m245 5 c11 -17 -1
-21 -15 -4 -8 9 -8 15 -2 15 6 0 14 -5 17 -11z m291 -26 c-11 -11 -19 6 -11
24 8 17 8 17 12 0 3 -10 2 -21 -1 -24z m224 27 c0 -5 -4 -10 -9 -10 -6 0 -13
5 -16 10 -3 6 1 10 9 10 9 0 16 -4 16 -10z m80 -5 c-7 -8 -17 -12 -23 -8 -6 4
-3 9 9 14 27 11 28 11 14 -6z m260 5 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10
0 6 5 10 10 10 6 0 10 -4 10 -10z m-1240 -10 c0 -5 -4 -10 -10 -10 -5 0 -10 5
-10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m343 -33 c-9 -15 -21 4 -15 25 5 21
6 21 12 2 4 -10 5 -22 3 -27z m163 16 c-10 -10 -19 5 -10 18 6 11 8 11 12 0 2
-7 1 -15 -2 -18z m340 0 c-10 -10 -19 5 -10 18 6 11 8 11 12 0 2 -7 1 -15 -2
-18z m224 5 c0 -13 -23 -5 -28 10 -2 7 2 10 12 6 9 -3 16 -11 16 -16z m304 7
c-4 -8 -10 -12 -15 -9 -11 6 -2 24 11 24 5 0 7 -7 4 -15z m-1324 -5 c0 -5 -7
-10 -16 -10 -8 0 -12 5 -9 10 3 6 10 10 16 10 5 0 9 -4 9 -10z m963 -5 c4 -8
2 -17 -3 -20 -6 -4 -10 3 -10 14 0 25 6 27 13 6z m315 -3 c2 -7 -2 -10 -12 -6
-9 3 -16 11 -16 16 0 13 23 5 28 -10z m-178 -2 c-19 -13 -30 -13 -30 0 0 6 10
10 23 10 18 0 19 -2 7 -10z m-1195 -10 c3 -5 -1 -10 -10 -10 -9 0 -13 5 -10
10 3 6 8 10 10 10 2 0 7 -4 10 -10z m185 -5 c-7 -9 -15 -13 -17 -11 -7 7 7 26
19 26 6 0 6 -6 -2 -15z m1090 -6 c0 -14 -17 -10 -23 5 -4 10 -1 13 9 9 7 -3
14 -9 14 -14z m180 11 c0 -5 -8 -10 -17 -10 -15 0 -16 2 -3 10 19 12 20 12 20
0z m-1395 -68 c-8 -8 -12 0 -19 38 l-7 35 16 -34 c8 -19 13 -36 10 -39z m165
46 c0 -16 -3 -19 -11 -11 -6 6 -8 16 -5 22 11 17 16 13 16 -11z m996 -15 c-11
-11 -19 6 -11 24 8 17 8 17 12 0 3 -10 2 -21 -1 -24z m-1346 11 c0 -8 -4 -12
-10 -9 -5 3 -10 10 -10 16 0 5 5 9 10 9 6 0 10 -7 10 -16z m1710 9 c0 -5 -5
-15 -10 -23 -8 -12 -10 -11 -10 8 0 12 5 22 10 22 6 0 10 -3 10 -7z m-1660
-13 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z
m180 0 c0 -5 -4 -10 -9 -10 -6 0 -13 5 -16 10 -3 6 1 10 9 10 9 0 16 -4 16
-10z m1260 6 c0 -2 -7 -6 -15 -10 -8 -3 -15 -1 -15 4 0 6 7 10 15 10 8 0 15
-2 15 -4z m165 -6 c3 -5 2 -10 -4 -10 -5 0 -13 5 -16 10 -3 6 -2 10 4 10 5 0
13 -4 16 -10z m-141 -32 c-10 -16 -22 -7 -17 13 4 15 8 17 14 8 4 -7 5 -17 3
-21z m52 5 c-7 -7 -26 7 -26 19 0 6 6 6 15 -2 9 -7 13 -15 11 -17z m-1426 7
c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z
m-175 -10 c-3 -5 -10 -10 -16 -10 -5 0 -9 5 -9 10 0 6 7 10 16 10 8 0 12 -4 9
-10z m45 -6 c0 -8 -5 -12 -10 -9 -6 4 -8 11 -5 16 9 14 15 11 15 -7z m1740 6
c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z
m-1910 -31 c0 -5 -4 -9 -10 -9 -5 0 -10 7 -10 16 0 8 5 12 10 9 6 -3 10 -10
10 -16z m230 17 c0 -11 -19 -15 -25 -6 -3 5 1 10 9 10 9 0 16 -2 16 -4z m1577
-2 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m77 -36 c-7 -11 -7
-11 -24 22 -13 25 -13 25 8 6 12 -10 19 -23 16 -28z m137 9 c-8 -8 -11 -7 -11
4 0 20 13 34 18 19 3 -7 -1 -17 -7 -23z m-1971 14 c0 -5 -7 -11 -15 -15 -15
-5 -20 5 -8 17 9 10 23 9 23 -2z m1938 -12 c12 -19 11 -19 -8 -9 -11 6 -20 15
-20 20 0 17 15 11 28 -11z m-1848 1 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6
8 10 11 10 2 0 4 -4 4 -10z m50 -11 c0 -5 -4 -9 -10 -9 -5 0 -10 7 -10 16 0 8
5 12 10 9 6 -3 10 -10 10 -16z m1640 6 c0 -8 -4 -15 -10 -15 -5 0 -7 7 -4 15
4 8 8 15 10 15 2 0 4 -7 4 -15z m-1850 -19 c0 -2 -9 -6 -20 -9 -11 -3 -20 -1
-20 4 0 5 9 9 20 9 11 0 20 -2 20 -4z m85 -6 c3 -5 -1 -10 -10 -10 -9 0 -13 5
-10 10 3 6 8 10 10 10 2 0 7 -4 10 -10z m1900 0 c3 -5 -1 -10 -10 -10 -9 0
-13 5 -10 10 3 6 8 10 10 10 2 0 7 -4 10 -10z m105 0 c0 -5 -4 -10 -9 -10 -6
0 -13 5 -16 10 -3 6 1 10 9 10 9 0 16 -4 16 -10z m-2230 -36 c0 -8 -4 -12 -10
-9 -5 3 -10 10 -10 16 0 5 5 9 10 9 6 0 10 -7 10 -16z m50 6 c0 -5 -4 -10 -10
-10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m140 -16 c0 -8 -4
-14 -10 -14 -5 0 -10 9 -10 21 0 11 5 17 10 14 6 -3 10 -13 10 -21z m2010 5
c0 -6 -4 -7 -10 -4 -5 3 -10 11 -10 16 0 6 5 7 10 4 6 -3 10 -11 10 -16z m95
10 c11 -17 -1 -21 -15 -4 -8 9 -8 15 -2 15 6 0 14 -5 17 -11z m55 6 c0 -18
-26 -55 -39 -55 -14 1 -14 2 2 14 9 7 17 21 17 30 0 9 5 16 10 16 6 0 10 -2
10 -5z m-2205 -15 c-3 -5 -10 -10 -16 -10 -5 0 -9 5 -9 10 0 6 7 10 16 10 8 0
12 -4 9 -10z m2005 0 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10
6 0 10 -4 10 -10z m-2075 -40 c-3 -5 -13 -6 -21 -3 -12 4 -13 8 -3 14 15 9 32
2 24 -11z m2203 11 c11 -7 11 -9 -1 -14 -9 -3 -18 1 -21 9 -6 16 2 18 22 5z
m102 -6 c0 -9 -6 -12 -16 -8 -13 5 -13 7 -2 14 17 11 18 11 18 -6z m-2407 -2
c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m47 -3 c0 -5 -2 -10 -4
-10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m2330 -43 c0 -5 -4
-5 -10 -2 -5 3 -10 14 -10 23 0 15 2 15 10 2 5 -8 10 -19 10 -23z m-2458 1
c-7 -7 -12 -8 -12 -2 0 14 12 26 19 19 2 -3 -1 -11 -7 -17z m102 7 c-6 -16
-24 -21 -24 -7 0 11 11 22 21 22 5 0 6 -7 3 -15z m46 -6 c0 -5 -4 -9 -10 -9
-5 0 -10 7 -10 16 0 8 5 12 10 9 6 -3 10 -10 10 -16z m2256 -6 c-10 -10 -19 5
-10 18 6 11 8 11 12 0 2 -7 1 -15 -2 -18z m159 -11 c-2 -2 -11 2 -19 11 -8 8
-12 18 -8 21 3 4 12 -1 19 -10 7 -10 11 -20 8 -22z m-2601 12 c11 -28 6 -38
-9 -18 -8 10 -15 22 -15 27 0 13 18 7 24 -9z m2646 7 c0 -6 -4 -13 -10 -16 -5
-3 -10 1 -10 9 0 9 5 16 10 16 6 0 10 -4 10 -9z m-2578 -43 c-9 -9 -15 -9 -24
0 -9 9 -7 12 12 12 19 0 21 -3 12 -12z m2508 2 c0 -5 -7 -10 -15 -10 -8 0 -15
5 -15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z m40 0 c0 -5 -5 -10 -11 -10 -5 0
-7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m60 -10 c0 -5 -7 -7 -15 -4 -8 4
-12 10 -9 15 6 11 24 2 24 -11z m-2700 6 c0 -2 -7 -6 -15 -10 -8 -3 -15 -1
-15 4 0 6 7 10 15 10 8 0 15 -2 15 -4z m2616 -43 c-10 -10 -19 5 -10 18 6 11
8 11 12 0 2 -7 1 -15 -2 -18z m-2596 2 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10
15 0 8 5 15 10 15 6 0 10 -7 10 -15z m41 2 c-1 -12 -15 -9 -19 4 -3 6 1 10 8
8 6 -3 11 -8 11 -12z m2609 -8 c0 -6 -4 -7 -10 -4 -5 3 -10 11 -10 16 0 6 5 7
10 4 6 -3 10 -11 10 -16z m-2754 -16 c-10 -10 -19 5 -10 18 6 11 8 11 12 0 2
-7 1 -15 -2 -18z m2844 11 c0 -8 -4 -12 -10 -9 -5 3 -10 10 -10 16 0 5 5 9 10
9 6 0 10 -7 10 -16z m-2885 -4 c3 -5 2 -10 -4 -10 -5 0 -13 5 -16 10 -3 6 -2
10 4 10 5 0 13 -4 16 -10z m2945 0 c0 -5 -7 -10 -16 -10 -8 0 -12 5 -9 10 3 6
10 10 16 10 5 0 9 -4 9 -10z m-2920 -30 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10
10 0 6 5 10 10 10 6 0 10 -4 10 -10z m50 0 c0 -5 -7 -10 -16 -10 -8 0 -12 5
-9 10 3 6 10 10 16 10 5 0 9 -4 9 -10z m2790 0 c0 -5 -4 -10 -10 -10 -5 0 -10
5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m50 -6 c0 -2 -7 -4 -15 -4 -8 0
-15 4 -15 10 0 5 7 7 15 4 8 -4 15 -8 15 -10z m-2930 -8 c0 -2 -7 -6 -15 -10
-8 -3 -15 -1 -15 4 0 6 7 10 15 10 8 0 15 -2 15 -4z m2980 -6 c0 -5 -7 -7 -15
-4 -8 4 -15 8 -15 10 0 2 7 4 15 4 8 0 15 -4 15 -10z m-2914 -37 c-4 -4 -11
-1 -16 7 -16 25 -11 35 7 17 9 -9 13 -20 9 -24z m-50 0 c-10 -10 -19 5 -10 18
6 11 8 11 12 0 2 -7 1 -15 -2 -18z m2884 17 c0 -5 -4 -10 -10 -10 -5 0 -10 5
-10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m44 -5 c3 -8 1 -15 -4 -15 -6 0 -10
7 -10 15 0 8 2 15 4 15 2 0 6 -7 10 -15z m-3016 -17 c-2 -6 -10 -14 -16 -16
-7 -2 -10 2 -6 12 7 18 28 22 22 4z m3116 -4 c4 -10 1 -14 -6 -12 -15 5 -23
28 -10 28 5 0 13 -7 16 -16z m-3164 -15 c0 -5 -4 -9 -10 -9 -5 0 -10 7 -10 16
0 8 5 12 10 9 6 -3 10 -10 10 -16z m3210 1 c0 -5 -4 -10 -10 -10 -5 0 -10 5
-10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-3130 -20 c0 -5 -10 -10 -22 -10
-19 0 -20 2 -8 10 19 13 30 13 30 0z m3050 0 c0 -5 -4 -10 -10 -10 -5 0 -10 5
-10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-3110 -10 c0 -5 -5 -10 -11 -10 -5
0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-48 -22 c-7 -7 -12 -8 -12 -2 0
14 12 26 19 19 2 -3 -1 -11 -7 -17z m3258 1 c0 -6 -4 -7 -10 -4 -5 3 -10 11
-10 16 0 6 5 7 10 4 6 -3 10 -11 10 -16z m-3174 -26 c-7 -7 -26 7 -26 19 0 6
6 6 15 -2 9 -7 13 -15 11 -17z m3094 12 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10
15 0 8 5 15 10 15 6 0 10 -7 10 -15z m-3144 -22 c-10 -10 -19 5 -10 18 6 11 8
11 12 0 2 -7 1 -15 -2 -18z m3194 6 c0 -5 -4 -9 -10 -9 -5 0 -10 7 -10 16 0 8
5 12 10 9 6 -3 10 -10 10 -16z m-3285 -19 c-3 -5 -11 -10 -16 -10 -6 0 -7 5
-4 10 3 6 11 10 16 10 6 0 7 -4 4 -10z m3375 0 c0 -5 -2 -10 -4 -10 -3 0 -8 5
-11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m-3420 -10 c0 -5 -4 -10 -10 -10 -5
0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m3465 -9 c-3 -6 -11 -11 -17
-11 -6 0 -6 6 2 15 14 17 26 13 15 -4z m-3385 -11 c0 -5 -7 -10 -16 -10 -8 0
-12 5 -9 10 3 6 10 10 16 10 5 0 9 -4 9 -10z m3333 -2 c-6 -11 -43 -10 -43 1
0 5 11 8 24 7 13 0 22 -4 19 -8z m27 -12 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1
-10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m-3455 -16 c-3 -5 -10 -10 -16 -10 -5
0 -9 5 -9 10 0 6 7 10 16 10 8 0 12 -4 9 -10z m3505 0 c0 -5 -4 -10 -9 -10 -6
0 -13 5 -16 10 -3 6 1 10 9 10 9 0 16 -4 16 -10z m-3430 -21 c0 -14 -17 -10
-23 5 -4 10 -1 13 9 9 7 -3 14 -9 14 -14z m3344 6 c-6 -16 -24 -21 -24 -7 0
11 11 22 21 22 5 0 6 -7 3 -15z m50 -26 c-10 -16 -24 -9 -24 12 0 18 2 19 15
9 8 -7 12 -16 9 -21z m-3440 6 c-4 -8 -10 -15 -15 -15 -4 0 -6 7 -3 15 4 8 10
15 15 15 4 0 6 -7 3 -15z m-89 -14 c3 -5 1 -12 -5 -16 -5 -3 -10 1 -10 9 0 18
6 21 15 7z m3615 -13 c0 -18 -2 -20 -9 -8 -6 8 -7 18 -5 22 9 14 14 9 14 -14z
m-3660 -19 c0 -6 -4 -7 -10 -4 -5 3 -10 11 -10 16 0 6 5 7 10 4 6 -3 10 -11
10 -16z m3711 -2 c-13 -13 -26 -3 -16 12 3 6 11 8 17 5 6 -4 6 -10 -1 -17z
m-3631 -7 c0 -5 -7 -10 -16 -10 -8 0 -12 5 -9 10 3 6 10 10 16 10 5 0 9 -4 9
-10z m3550 0 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10
-4 10 -10z m22 -41 c-12 -10 -23 -15 -27 -10 -3 5 4 12 15 16 11 3 20 14 21
23 0 12 2 13 6 4 3 -8 -4 -22 -15 -33z m-3642 10 c-37 -10 -50 -10 -50 0 0 11
49 19 65 12 5 -3 -1 -8 -15 -12z m3715 0 c12 -19 -5 -23 -22 -6 -16 16 -16 17
-1 17 9 0 20 -5 23 -11z m-3665 -25 c0 -8 -4 -12 -10 -9 -5 3 -10 10 -10 16 0
5 5 9 10 9 6 0 10 -7 10 -16z m3630 -10 c0 -8 -4 -14 -10 -14 -5 0 -10 9 -10
21 0 11 5 17 10 14 6 -3 10 -13 10 -21z m-3670 2 c0 -3 -4 -8 -10 -11 -5 -3
-10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m-90 -25 c0 -6 -4 -13 -10 -16
-5 -3 -10 1 -10 9 0 9 5 16 10 16 6 0 10 -4 10 -9z m3850 -12 c0 -11 -4 -17
-10 -14 -5 3 -10 13 -10 21 0 8 5 14 10 14 6 0 10 -9 10 -21z m-3890 -19 c8
-5 11 -10 5 -10 -5 0 -17 5 -25 10 -8 5 -10 10 -5 10 6 0 17 -5 25 -10z m3867
-17 c-8 -7 -37 9 -31 19 3 5 12 4 20 -3 8 -6 13 -14 11 -16z m73 17 c0 -5 -7
-10 -16 -10 -8 0 -12 5 -9 10 3 6 10 10 16 10 5 0 9 -4 9 -10z m-3860 -4 c0
-11 -19 -15 -25 -6 -3 5 1 10 9 10 9 0 16 -2 16 -4z m-90 -35 c0 -5 -7 -11
-15 -15 -15 -5 -20 5 -8 17 9 10 23 9 23 -2z m3872 -13 c-6 -6 -15 -8 -19 -4
-4 4 -1 11 7 16 19 12 27 3 12 -12z m88 0 c0 -13 -23 -5 -28 10 -2 7 2 10 12
6 9 -3 16 -11 16 -16z m-3890 -8 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6
-1 10 4 10 6 0 11 -4 11 -10z m-40 -9 c0 -6 -4 -13 -10 -16 -5 -3 -10 1 -10 9
0 9 5 16 10 16 6 0 10 -4 10 -9z m3890 -12 c0 -5 -4 -9 -10 -9 -5 0 -10 7 -10
16 0 8 5 12 10 9 6 -3 10 -10 10 -16z m85 -47 c-8 -9 -25 20 -18 31 3 5 10 2
15 -7 6 -10 7 -20 3 -24z m-4065 18 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0
6 5 10 10 10 6 0 10 -4 10 -10z m-40 -20 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10
10 0 6 5 10 10 10 6 0 10 -4 10 -10z m4151 -13 c-13 -13 -26 -3 -16 12 3 6 11
8 17 5 6 -4 6 -10 -1 -17z m-4071 3 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0
6 5 10 10 10 6 0 10 -4 10 -10z m3990 0 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15
10 0 6 7 10 15 10 8 0 15 -4 15 -10z m37 -32 c-3 -7 -5 -2 -5 12 0 14 2 19 5
13 2 -7 2 -19 0 -25z m-4072 12 c-3 -5 -12 -10 -18 -10 -7 0 -6 4 3 10 19 12
23 12 15 0z m-45 -20 c0 -5 -8 -10 -17 -10 -15 0 -16 2 -3 10 19 12 20 12 20
0z m80 -12 c0 -13 -23 -5 -28 10 -2 7 2 10 12 6 9 -3 16 -11 16 -16z m4002 0
c-6 -6 -15 -8 -19 -4 -4 4 -1 11 7 16 19 12 27 3 12 -12z m88 1 c0 -6 -4 -7
-10 -4 -5 3 -10 11 -10 16 0 6 5 7 10 4 6 -3 10 -11 10 -16z m-4139 -32 c-8
-8 -11 -7 -11 4 0 20 13 34 18 19 3 -7 -1 -17 -7 -23z m4099 19 c0 -8 -4 -18
-10 -21 -5 -3 -10 3 -10 14 0 12 5 21 10 21 6 0 10 -6 10 -14z m-4176 -51 c-4
-8 -10 -15 -15 -15 -4 0 -6 7 -3 15 4 8 10 15 15 15 4 0 6 -7 3 -15z m4256 0
c0 -8 -2 -15 -4 -15 -2 0 -6 7 -10 15 -3 8 -1 15 4 15 6 0 10 -7 10 -15z
m-4290 -25 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4
15 -10z m75 0 c-3 -5 -13 -10 -21 -10 -8 0 -12 5 -9 10 3 6 13 10 21 10 8 0
12 -4 9 -10z m4180 0 c3 -5 -1 -10 -9 -10 -9 0 -16 5 -16 10 0 6 4 10 9 10 6
0 13 -4 16 -10z m85 2 c0 -4 -13 -13 -30 -19 -35 -13 -41 -3 -7 13 28 15 37
16 37 6z m-4310 -16 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6
0 10 -2 10 -4z m30 -26 c8 -5 11 -12 7 -16 -4 -4 -15 0 -24 9 -18 18 -8 23 17
7z m4200 -5 c-7 -8 -17 -12 -22 -9 -6 4 -5 10 3 15 22 14 33 10 19 -6z m-4270
-5 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z
m4350 0 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10
-10z m-4315 -19 c3 -5 1 -12 -5 -16 -5 -3 -10 1 -10 9 0 18 6 21 15 7z m4275
-1 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z
m-4350 -49 c0 -6 -4 -13 -10 -16 -5 -3 -10 1 -10 9 0 9 5 16 10 16 6 0 10 -4
10 -9z m4430 -7 c0 -8 -4 -12 -10 -9 -5 3 -10 10 -10 16 0 5 5 9 10 9 6 0 10
-7 10 -16z m-34 -31 c-7 -7 -26 7 -26 19 0 6 6 6 15 -2 9 -7 13 -15 11 -17z
m-4436 7 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4 15
-10z m75 0 c-3 -5 -10 -10 -16 -10 -5 0 -9 5 -9 10 0 6 7 10 16 10 8 0 12 -4
9 -10z m4434 -11 c-22 -14 -33 -10 -19 6 7 8 17 12 22 9 6 -4 5 -10 -3 -15z
m-4479 -5 c0 -8 -5 -12 -10 -9 -6 4 -8 11 -5 16 9 14 15 11 15 -7z m44 -35
c-6 -11 -24 -2 -24 11 0 5 7 7 15 4 8 -4 12 -10 9 -15z m4356 12 c0 -6 -4 -13
-10 -16 -5 -3 -10 1 -10 9 0 9 5 16 10 16 6 0 10 -4 10 -9z m-4436 -16 c-4 -8
-10 -12 -15 -9 -11 6 -2 24 11 24 5 0 7 -7 4 -15z m4516 5 c13 -8 13 -10 -2
-10 -9 0 -20 5 -23 10 -8 13 5 13 25 0z m-4477 -15 c4 -8 2 -17 -3 -20 -6 -4
-10 3 -10 14 0 25 6 27 13 6z m4433 -12 c-10 -10 -19 5 -10 18 6 11 8 11 12 0
2 -7 1 -15 -2 -18z m-4510 -60 c-10 -10 -19 5 -10 18 6 11 8 11 12 0 2 -7 1
-15 -2 -18z m4585 -14 c-9 -23 -10 -22 -10 11 -1 23 2 31 9 24 7 -7 7 -19 1
-35z m-4552 -10 c-19 -12 -24 -2 -8 17 9 11 14 13 17 4 3 -7 -2 -16 -9 -21z
m-69 6 c10 -12 10 -15 -4 -15 -9 0 -16 7 -16 15 0 8 2 15 4 15 2 0 9 -7 16
-15z m4585 5 c3 -5 2 -10 -4 -10 -5 0 -13 5 -16 10 -3 6 -2 10 4 10 5 0 13 -4
16 -10z m-1000 -23 c11 -17 65 -318 65 -362 0 -2 17 -9 38 -15 67 -21 165 -63
259 -111 91 -47 255 -149 281 -176 7 -7 18 -13 24 -13 6 0 9 -2 6 -5 -3 -3
-41 11 -84 31 -168 77 -481 180 -498 163 -7 -6 17 -178 115 -834 26 -170 44
-237 74 -265 26 -24 112 -50 168 -50 44 0 52 -24 10 -35 -28 -7 -395 2 -471
11 -47 6 -67 30 -39 46 24 13 22 52 -19 346 -19 141 -49 356 -65 477 -16 120
-32 221 -34 224 -3 2 -27 -43 -54 -100 -27 -57 -83 -176 -126 -264 -43 -88
-106 -218 -140 -290 -110 -228 -205 -417 -217 -432 -29 -35 -42 -17 -199 297
-53 108 -112 227 -129 265 -18 39 -43 94 -57 123 -28 63 -26 63 -151 -9 -47
-27 -88 -49 -93 -49 -18 0 -22 -23 -59 -305 -25 -188 -26 -246 -2 -273 18 -22
119 -46 147 -36 9 4 15 0 15 -9 0 -14 -32 -15 -252 -14 -191 2 -252 5 -256 15
-2 8 11 12 46 12 55 0 97 25 113 68 19 50 73 397 64 411 -3 6 -64 -20 -138
-58 -73 -37 -167 -81 -209 -97 -77 -29 -77 -29 -98 -83 -69 -181 -237 -291
-470 -308 -136 -9 -323 24 -381 68 -23 17 -24 21 -22 151 4 197 11 248 33 248
15 0 20 -10 25 -47 19 -144 73 -231 172 -279 46 -23 75 -30 138 -32 93 -4 144
7 200 43 45 29 90 87 100 128 l7 27 -113 0 c-114 0 -237 14 -252 29 -4 4 45
11 110 14 135 7 266 33 403 79 96 33 340 151 387 188 14 11 30 20 35 20 4 0
41 20 81 43 383 231 653 319 925 301 56 -3 102 -10 102 -13 0 -4 -45 -13 -100
-19 -106 -12 -267 -49 -287 -64 -9 -7 16 -66 97 -229 61 -120 112 -219 115
-219 3 0 23 37 44 83 135 287 198 420 265 561 42 87 76 163 76 167 0 14 -114
10 -224 -6 -182 -28 -465 -116 -655 -206 -35 -17 -65 -29 -66 -27 -2 2 -15 30
-30 63 -23 54 -28 58 -39 43 -7 -10 -16 -44 -19 -76 -4 -32 -9 -65 -12 -74 -5
-12 -409 -218 -428 -218 -4 0 -30 -11 -59 -24 -162 -74 -334 -131 -496 -163
-121 -24 -394 -24 -515 0 -128 26 -278 77 -398 137 -57 28 -108 55 -114 60 -5
4 -32 20 -60 35 -27 16 -52 31 -55 35 -3 3 -33 26 -68 50 -34 23 -60 45 -58
48 3 2 48 -17 101 -42 480 -232 891 -264 1354 -104 165 57 253 94 484 207
l204 100 11 78 c38 294 72 500 85 531 22 47 33 32 152 -213 59 -121 108 -222
110 -223 2 -2 64 17 138 42 218 74 360 99 562 101 l170 2 68 139 c99 200 103
205 132 161z m1085 13 c0 -5 -7 -10 -16 -10 -8 0 -12 5 -9 10 3 6 10 10 16 10
5 0 9 -4 9 -10z m-4630 -16 c0 -8 -5 -12 -10 -9 -6 4 -8 11 -5 16 9 14 15 11
15 -7z m1458 -14 c120 -21 112 -9 111 -167 -2 -155 -8 -198 -29 -198 -9 0 -18
19 -25 56 -27 134 -150 230 -297 231 -150 0 -248 -89 -248 -228 0 -113 84
-226 256 -343 91 -63 184 -139 184 -151 0 -5 -44 -18 -97 -30 -54 -12 -133
-30 -176 -40 l-78 -17 -36 24 c-101 68 -211 209 -246 313 -82 247 43 480 288
537 57 14 283 33 315 27 8 -2 44 -8 78 -14z m3082 -5 c-7 -9 -15 -13 -17 -11
-7 7 7 26 19 26 6 0 6 -6 -2 -15z m36 -43 c-11 -10 -14 3 -8 33 l7 30 3 -28
c2 -16 1 -31 -2 -35z m-4526 38 c0 -13 -11 -13 -30 0 -12 8 -11 10 8 10 12 0
22 -4 22 -10z m-85 -10 c-3 -5 -11 -10 -16 -10 -6 0 -7 5 -4 10 3 6 11 10 16
10 6 0 7 -4 4 -10z m4660 1 c3 -5 1 -12 -4 -15 -5 -3 -11 1 -15 9 -6 16 9 21
19 6z m-4615 -22 c0 -5 -4 -9 -10 -9 -5 0 -10 7 -10 16 0 8 5 12 10 9 6 -3 10
-10 10 -16z m-83 -61 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z
m4719 -15 c-11 -11 -19 6 -11 24 8 17 8 17 12 0 3 -10 2 -21 -1 -24z m-4671
-2 c-3 -6 -11 -11 -17 -11 -6 0 -6 6 2 15 14 17 26 13 15 -4z m4642 -8 c-8 -7
-37 9 -31 19 3 5 12 4 20 -3 8 -6 13 -14 11 -16z m-4717 -3 c0 -5 -4 -10 -10
-10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m4736 -78 c-12 -11
-15 5 -8 44 l7 39 3 -39 c2 -21 1 -41 -2 -44z m51 72 c-3 -3 -12 -4 -19 -1 -8
3 -5 6 6 6 11 1 17 -2 13 -5z m-77 -18 c0 -2 -7 -9 -15 -16 -12 -10 -15 -10
-15 4 0 9 7 16 15 16 8 0 15 -2 15 -4z m-4620 -16 c0 -5 -4 -10 -9 -10 -6 0
-13 5 -16 10 -3 6 1 10 9 10 9 0 16 -4 16 -10z m-85 -19 c-3 -6 -11 -8 -17 -5
-6 4 -5 9 3 15 16 10 23 4 14 -10z m4774 10 c8 -5 9 -11 3 -15 -5 -3 -15 1
-22 9 -14 16 -3 20 19 6z m-4729 -22 c0 -5 -4 -9 -10 -9 -5 0 -10 7 -10 16 0
8 5 12 10 9 6 -3 10 -10 10 -16z m-70 -64 c0 -8 -4 -15 -10 -15 -5 0 -10 7
-10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m4824 0 c-4 -8 -8 -15 -10 -15 -2 0
-4 7 -4 15 0 8 4 15 10 15 5 0 7 -7 4 -15z m-4785 -21 c-15 -18 -22 -13 -13
10 3 9 10 13 16 10 5 -3 4 -12 -3 -20z m4749 -2 c2 -7 -2 -10 -12 -6 -9 3 -16
11 -16 16 0 13 23 5 28 -10z m-4813 -12 c3 -5 -1 -10 -9 -10 -9 0 -16 5 -16
10 0 6 4 10 9 10 6 0 13 -4 16 -10z m4885 -5 c0 -9 -6 -12 -15 -9 -8 4 -12 10
-9 15 8 14 24 10 24 -6z m-85 -15 c-3 -5 -10 -10 -16 -10 -5 0 -9 5 -9 10 0 6
7 10 16 10 8 0 12 -4 9 -10z m-4715 -10 c0 -5 -4 -10 -9 -10 -6 0 -13 5 -16
10 -3 6 1 10 9 10 9 0 16 -4 16 -10z m4790 -21 c0 -14 -17 -10 -23 5 -4 10 -1
13 9 9 7 -3 14 -9 14 -14z m-4870 -4 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15
0 8 5 15 10 15 6 0 10 -7 10 -15z m4820 0 c0 -8 -4 -15 -10 -15 -5 0 -7 7 -4
15 4 8 8 15 10 15 2 0 4 -7 4 -15z m-4775 -4 c3 -5 1 -12 -5 -16 -5 -3 -10 1
-10 9 0 18 6 21 15 7z m-65 -77 c0 -8 -4 -12 -10 -9 -5 3 -10 10 -10 16 0 5 5
9 10 9 6 0 10 -7 10 -16z m4904 1 c-4 -8 -8 -15 -10 -15 -2 0 -4 7 -4 15 0 8
4 15 10 15 5 0 7 -7 4 -15z m-4856 -7 c-2 -6 -10 -14 -16 -16 -7 -2 -10 2 -6
12 7 18 28 22 22 4z m4812 -4 c0 -8 -4 -12 -10 -9 -5 3 -10 10 -10 16 0 5 5 9
10 9 6 0 10 -7 10 -16z m-4885 -24 c3 -5 -1 -10 -9 -10 -9 0 -16 5 -16 10 0 6
4 10 9 10 6 0 13 -4 16 -10z m4965 0 c0 -5 -8 -10 -17 -10 -15 0 -16 2 -3 10
19 12 20 12 20 0z m-4880 -21 c0 -5 -12 -6 -26 -3 -24 6 -25 5 -15 -14 14 -26
14 -32 1 -32 -5 0 -10 9 -10 19 0 11 -6 22 -12 24 -10 4 -10 7 0 15 16 11 62
4 62 -9z m4785 1 c-3 -5 -10 -10 -16 -10 -5 0 -9 5 -9 10 0 6 7 10 16 10 8 0
12 -4 9 -10z m75 -31 c0 -14 -17 -10 -23 5 -4 10 -1 13 9 9 7 -3 14 -9 14 -14z
m-4936 -4 c-4 -8 -10 -12 -15 -9 -11 6 -2 24 11 24 5 0 7 -7 4 -15z m4896 -6
c0 -5 -4 -9 -10 -9 -5 0 -10 7 -10 16 0 8 5 12 10 9 6 -3 10 -10 10 -16z
m-4895 -460 c11 -17 -1 -21 -15 -4 -8 9 -8 15 -2 15 6 0 14 -5 17 -11z m37
-19 c-9 -16 -21 -30 -26 -30 -6 0 -5 5 2 12 7 7 12 20 12 30 0 10 7 18 15 18
12 0 11 -5 -3 -30z m4878 14 c0 -8 -4 -12 -10 -9 -5 3 -10 10 -10 16 0 5 5 9
10 9 6 0 10 -7 10 -16z m50 7 c0 -6 -4 -13 -10 -16 -5 -3 -10 1 -10 9 0 9 5
16 10 16 6 0 10 -4 10 -9z m-4885 -41 c3 -5 -1 -10 -10 -10 -9 0 -13 5 -10 10
3 6 8 10 10 10 2 0 7 -4 10 -10z m4815 0 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15
10 0 6 7 10 15 10 8 0 15 -4 15 -10z m-4905 -10 c-3 -5 -10 -10 -16 -10 -5 0
-9 5 -9 10 0 6 7 10 16 10 8 0 12 -4 9 -10z m4986 -3 c-1 -12 -15 -9 -19 4 -3
6 1 10 8 8 6 -3 11 -8 11 -12z m-4923 -43 c-4 -3 -12 5 -18 18 -12 22 -12 22
6 6 10 -10 15 -20 12 -24z m4852 15 c-7 -11 -14 -18 -17 -15 -3 3 0 12 7 21
18 21 23 19 10 -6z m-4894 -26 c-11 -11 -19 6 -11 24 8 17 8 17 12 0 3 -10 2
-21 -1 -24z m4931 5 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z
m-47 -69 c0 -5 -4 -9 -10 -9 -5 0 -10 7 -10 16 0 8 5 12 10 9 6 -3 10 -10 10
-16z m-4845 -48 c-6 -5 -44 41 -44 54 0 6 11 -3 24 -20 13 -16 22 -31 20 -34z
m25 55 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10
-4z m4864 -11 c-6 -16 -24 -21 -24 -7 0 11 11 22 21 22 5 0 6 -7 3 -15z
m-4834 -35 c0 -5 -7 -10 -16 -10 -8 0 -12 5 -9 10 3 6 10 10 16 10 5 0 9 -4 9
-10z m4757 4 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-4852
-14 c3 -5 -1 -10 -9 -10 -9 0 -16 5 -16 10 0 6 4 10 9 10 6 0 13 -4 16 -10z
m4945 0 c0 -5 -7 -10 -16 -10 -8 0 -12 5 -9 10 3 6 10 10 16 10 5 0 9 -4 9
-10z m-31 -52 c1 -5 -3 -8 -9 -8 -5 0 -10 12 -9 28 0 24 1 25 9 7 5 -11 9 -23
9 -27z m-4839 11 c0 -6 -4 -7 -10 -4 -5 3 -10 11 -10 16 0 6 5 7 10 4 6 -3 10
-11 10 -16z m4786 -6 c-10 -10 -19 5 -10 18 6 11 8 11 12 0 2 -7 1 -15 -2 -18z
m-4831 7 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z
m65 -67 c0 -5 -5 -15 -10 -23 -8 -12 -10 -11 -10 8 0 12 5 22 10 22 6 0 10 -3
10 -7z m4760 -2 c0 -6 -4 -13 -10 -16 -5 -3 -10 1 -10 9 0 9 5 16 10 16 6 0
10 -4 10 -9z m-4810 -17 c0 -8 -4 -12 -10 -9 -5 3 -10 10 -10 16 0 5 5 9 10 9
6 0 10 -7 10 -16z m4850 7 c0 -6 -4 -13 -10 -16 -5 -3 -10 1 -10 9 0 9 5 16
10 16 6 0 10 -4 10 -9z m-4770 -34 c0 -8 -15 -14 -37 -15 -21 -2 -46 -5 -55
-8 -10 -3 -18 -1 -18 5 0 6 17 11 38 11 20 0 44 5 52 10 19 13 20 12 20 -3z
m4710 3 c12 -8 9 -10 -12 -10 -16 0 -28 5 -28 10 0 13 20 13 40 0z m75 -19
c10 -17 -10 -20 -35 -5 l-25 13 27 1 c15 0 29 -4 33 -9z m-80 -30 c-3 -6 -11
-11 -17 -11 -6 0 -6 6 2 15 14 17 26 13 15 -4z m-4715 -1 c0 -5 -4 -10 -10
-10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m4760 -16 c0 -8 -4
-14 -10 -14 -5 0 -10 9 -10 21 0 11 5 17 10 14 6 -3 10 -13 10 -21z m-4805 7
c3 -5 1 -12 -5 -16 -5 -3 -10 1 -10 9 0 18 6 21 15 7z m61 -78 c-10 -10 -19 5
-10 18 6 11 8 11 12 0 2 -7 1 -15 -2 -18z m4674 11 c0 -8 -5 -12 -10 -9 -6 4
-8 11 -5 16 9 14 15 11 15 -7z m-4709 -21 c13 -15 12 -15 -8 -4 -24 12 -29 21
-14 21 5 0 15 -7 22 -17z m4759 8 c0 -5 -7 -11 -14 -14 -10 -4 -13 -1 -9 9 6
15 23 19 23 5z m-81 -25 c10 -12 8 -13 -8 -9 -12 3 -21 9 -21 14 0 14 16 11
29 -5z m-4599 -6 c0 -5 -7 -10 -16 -10 -8 0 -12 5 -9 10 3 6 10 10 16 10 5 0
9 -4 9 -10z m-45 -10 c-3 -5 -11 -10 -16 -10 -6 0 -7 5 -4 10 3 6 11 10 16 10
6 0 7 -4 4 -10z m-40 -10 c-3 -5 -10 -10 -16 -10 -5 0 -9 5 -9 10 0 6 7 10 16
10 8 0 12 -4 9 -10z m4769 -11 c-3 -5 -10 -7 -15 -3 -5 3 -7 10 -3 15 3 5 10
7 15 3 5 -3 7 -10 3 -15z m-4699 -10 c11 -17 -1 -21 -15 -4 -8 9 -8 15 -2 15
6 0 14 -5 17 -11z m4620 1 c-3 -5 -11 -10 -16 -10 -6 0 -7 5 -4 10 3 6 11 10
16 10 6 0 7 -4 4 -10z m-4661 -15 c3 -8 1 -15 -4 -15 -6 0 -10 7 -10 15 0 8 2
15 4 15 2 0 6 -7 10 -15z m4706 0 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8
5 15 10 15 6 0 10 -7 10 -15z m-4630 -62 c0 -5 -5 -15 -10 -23 -8 -12 -10 -11
-10 8 0 12 5 22 10 22 6 0 10 -3 10 -7z m4560 -3 c0 -5 -4 -10 -9 -10 -6 0
-13 5 -16 10 -3 6 1 10 9 10 9 0 16 -4 16 -10z m-4602 -21 c12 -19 11 -19 -8
-9 -11 6 -20 15 -20 20 0 17 15 11 28 -11z m4634 -1 c-7 -7 -12 -8 -12 -2 0
14 12 26 19 19 2 -3 -1 -11 -7 -17z m-4554 -10 c-3 -7 -13 -15 -24 -17 -16 -3
-17 -1 -5 13 16 19 34 21 29 4z m4485 -2 c4 -10 1 -13 -9 -9 -15 6 -19 23 -5
23 5 0 11 -7 14 -14z m34 -28 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0
-25z m-4567 12 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4
4 -10z m-30 -4 c0 -2 -7 -9 -15 -16 -12 -10 -15 -10 -15 4 0 9 7 16 15 16 8 0
15 -2 15 -4z m4645 -16 c3 -5 2 -10 -4 -10 -5 0 -13 5 -16 10 -3 6 -2 10 4 10
5 0 13 -4 16 -10z m-4575 -10 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5
10 10 10 6 0 10 -4 10 -10z m4490 0 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0
6 5 10 10 10 6 0 10 -4 10 -10z m-4534 -27 c-10 -10 -19 5 -10 18 6 11 8 11
12 0 2 -7 1 -15 -2 -18z m4574 12 c0 -8 -4 -15 -10 -15 -5 0 -7 7 -4 15 4 8 8
15 10 15 2 0 4 -7 4 -15z m-4495 -54 c3 -5 1 -12 -5 -16 -5 -3 -10 1 -10 9 0
18 6 21 15 7z m4421 -18 c-10 -10 -19 5 -10 18 6 11 8 11 12 0 2 -7 1 -15 -2
-18z m-4462 -9 c4 -10 1 -14 -6 -12 -15 5 -23 28 -10 28 5 0 13 -7 16 -16z
m665 10 c24 -5 31 -12 31 -30 0 -29 -11 -31 -27 -4 -7 11 -27 24 -45 29 -39
11 -9 15 41 5z m151 -109 l0 -115 -37 0 -38 0 0 115 0 115 38 0 37 0 0 -115z
m125 0 l0 -115 -35 0 -35 0 0 115 0 115 35 0 35 0 0 -115z m211 97 c32 -25 44
-55 44 -106 0 -51 -37 -106 -72 -106 -16 0 -18 3 -9 14 8 9 11 47 9 107 -3 91
-4 94 -27 97 -37 6 -51 -22 -51 -97 0 -84 7 -106 33 -120 19 -9 18 -10 -8 -4
-42 9 -72 30 -79 56 -9 35 -7 107 4 127 25 46 116 65 156 32z m142 6 c-2 -7 6
-42 17 -77 17 -56 20 -61 32 -45 20 27 17 111 -4 123 -14 8 -6 10 34 11 43 0
51 -3 47 -15 -7 -18 23 -117 39 -127 14 -8 -11 -98 -28 -98 -7 0 -21 28 -33
63 -11 34 -24 62 -28 62 -5 0 -17 -28 -27 -62 -10 -35 -24 -63 -32 -63 -7 0
-29 47 -50 108 -21 59 -42 109 -47 110 -23 10 -2 22 37 22 32 0 45 -4 43 -12z
m226 4 c-6 -4 -18 -27 -27 -52 l-16 -45 6 44 c4 31 2 45 -8 52 -10 6 -3 9 21
9 19 0 30 -4 24 -8z m171 -4 c2 -7 11 -39 21 -71 9 -33 20 -55 24 -50 4 4 14
35 22 68 l15 60 42 3 c33 3 41 0 35 -10 -4 -7 -7 -59 -6 -115 l1 -103 -35 0
-35 0 6 93 c8 112 -6 109 -33 -8 -16 -66 -23 -81 -40 -83 -13 -2 -22 3 -25 15
-3 10 -14 51 -23 91 -10 39 -22 72 -27 72 -5 0 -7 -41 -5 -90 2 -50 -1 -90 -5
-90 -4 0 -7 52 -6 115 l2 115 34 0 c19 0 36 -6 38 -12z m322 -33 c8 -22 24
-67 35 -100 11 -33 25 -66 31 -72 7 -10 -1 -13 -34 -13 -42 0 -44 1 -46 32 -1
17 -7 34 -13 38 -6 4 -10 11 -9 16 1 5 -1 22 -6 37 -7 26 -9 27 -23 14 -8 -8
-14 -27 -14 -41 0 -14 -4 -26 -8 -26 -8 0 -25 -49 -22 -62 1 -5 -3 -8 -9 -8
-6 0 -9 6 -6 13 2 6 21 58 42 115 42 114 57 124 82 57z m220 -26 c18 -39 37
-68 43 -64 6 4 10 -13 10 -45 0 -47 -2 -51 -22 -48 -15 2 -34 27 -67 90 -25
48 -49 88 -53 88 -5 0 -8 -40 -8 -90 0 -49 -3 -90 -7 -90 -5 0 -7 48 -5 106 3
80 0 109 -10 115 -8 5 6 9 37 8 l50 0 32 -70z m65 59 c-9 -9 -15 -9 -24 0 -9
9 -7 12 12 12 19 0 21 -3 12 -12z m263 -2 c42 -18 55 -42 55 -105 0 -38 -6
-56 -24 -78 -22 -25 -32 -29 -108 -34 -45 -3 -87 -2 -92 3 -6 5 -5 8 2 8 9 0
12 28 12 98 0 63 -4 102 -12 110 -18 18 123 16 167 -2z m215 -78 c18 -51 39
-103 47 -115 16 -27 19 -25 -38 -27 -44 -1 -46 0 -47 26 -1 39 -9 48 -42 48
-32 0 -39 -7 -42 -44 -2 -18 -8 -26 -21 -26 -14 0 -17 4 -10 13 5 6 27 58 48
115 49 132 59 133 105 10z m240 45 l-1 -48 -20 38 c-11 20 -24 37 -29 37 -16
0 -13 -197 3 -203 6 -3 -7 -6 -30 -8 -24 -1 -48 -2 -54 -3 -8 -1 -11 32 -10
107 2 115 -8 136 -35 72 -19 -45 -31 -38 -26 14 l4 41 99 0 99 0 0 -47z m139
-40 c17 -49 37 -100 45 -115 17 -33 14 -36 -38 -34 -33 2 -40 5 -43 26 -6 43
-12 50 -42 50 -24 0 -32 -5 -37 -24 -3 -14 -3 -29 0 -35 9 -13 -9 -22 -28 -15
-10 4 -13 11 -8 18 5 6 26 57 47 114 49 133 62 135 104 15z m301 52 c0 -19 -2
-35 -4 -35 -3 0 -18 14 -34 30 -34 33 -53 37 -72 15 -17 -21 -1 -39 49 -54 79
-23 100 -89 44 -139 -22 -20 -34 -23 -82 -19 l-56 4 -3 37 c-4 44 7 46 37 5
18 -24 29 -30 54 -27 24 2 33 8 35 24 3 18 -6 25 -45 40 -60 21 -71 32 -79 69
-4 22 0 38 16 58 19 24 28 27 81 27 l59 0 0 -35z m117 23 c-22 -18 -20 -184 3
-198 25 -16 59 -11 70 9 15 29 13 188 -2 194 -7 3 34 6 92 6 80 1 111 -3 128
-15 24 -16 30 -62 11 -81 -8 -8 -5 -17 11 -33 35 -35 9 -98 -42 -101 -25 -1
-114 -1 -135 0 -14 1 -15 3 -5 9 9 6 12 35 10 108 -3 82 -6 99 -20 102 -14 3
-17 -10 -20 -92 -3 -107 -12 -123 -71 -133 -52 -8 -94 12 -106 51 -5 17 -11
66 -13 109 l-3 77 54 0 c46 0 52 -2 38 -12z m928 -7 c-3 -6 -11 -11 -17 -11
-6 0 -6 6 2 15 14 17 26 13 15 -4z m-3922 -16 c-4 -20 0 -27 23 -36 82 -32 94
-40 100 -64 9 -36 -3 -73 -29 -86 -20 -11 -21 -11 -9 4 20 23 0 53 -45 67 -21
7 -46 19 -55 28 -22 19 -23 75 -2 96 21 21 22 20 17 -9z m3842 15 c3 -5 1 -10
-4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m-4335 -10 c0 -5 -8
-10 -18 -10 -10 0 -23 -5 -30 -12 -7 -7 -12 -8 -12 -2 0 6 10 16 23 22 29 14
37 14 37 2z m-90 -35 c-7 -9 -15 -13 -17 -11 -7 7 7 26 19 26 6 0 6 -6 -2 -15z
m4510 -6 c0 -14 -17 -10 -23 5 -4 10 -1 13 9 9 7 -3 14 -9 14 -14z m-4425 2
c10 -17 -12 -21 -25 -6 -10 12 -10 15 3 15 9 0 18 -4 22 -9z m4345 1 c0 -13
-12 -22 -22 -16 -10 6 -1 24 13 24 5 0 9 -4 9 -8z m-4386 -27 c3 -8 1 -15 -4
-15 -6 0 -10 7 -10 15 0 8 2 15 4 15 2 0 6 -7 10 -15z m4426 0 c0 -8 -4 -15
-10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m-4345 -52 c-2
-10 -4 -20 -4 -23 -1 -3 -5 2 -10 10 -10 15 -6 30 9 30 4 0 7 -8 5 -17z m4265
3 c0 -8 -4 -18 -10 -21 -5 -3 -10 3 -10 14 0 12 5 21 10 21 6 0 10 -6 10 -14z
m40 -21 c0 -8 -7 -15 -16 -15 -14 0 -14 3 -4 15 7 8 14 15 16 15 2 0 4 -7 4
-15z m-4345 -5 c3 -5 2 -10 -4 -10 -5 0 -13 5 -16 10 -3 6 -2 10 4 10 5 0 13
-4 16 -10z m77 -12 c-7 -7 -12 -8 -12 -2 0 14 12 26 19 19 2 -3 -1 -11 -7 -17z
m430 -32 l22 -16 -31 0 c-27 0 -31 4 -35 30 -5 36 3 49 13 22 4 -11 18 -27 31
-36z m3753 44 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4
11 -10z m-4260 -49 c-3 -6 -11 -8 -17 -5 -6 4 -5 9 3 15 16 10 23 4 14 -10z
m4302 13 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-4217 -14 c0
-5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m4175
0 c-3 -5 -10 -10 -16 -10 -5 0 -9 5 -9 10 0 6 7 10 16 10 8 0 12 -4 9 -10z
m85 0 c0 -5 -4 -10 -9 -10 -6 0 -13 5 -16 10 -3 6 1 10 9 10 9 0 16 -4 16 -10z
m-3795 -10 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11
-10z m3741 -27 c-11 -11 -17 4 -9 23 7 18 8 18 11 1 2 -10 1 -21 -2 -24z
m-4250 0 c-10 -10 -19 5 -10 18 6 11 8 11 12 0 2 -7 1 -15 -2 -18z m84 -38 c0
-8 -2 -15 -4 -15 -2 0 -6 7 -10 15 -3 8 -1 15 4 15 6 0 10 -7 10 -15z m4090 6
c0 -6 -4 -13 -10 -16 -5 -3 -10 1 -10 9 0 9 5 16 10 16 6 0 10 -4 10 -9z
m-4123 -37 c-9 -9 -28 6 -21 18 4 6 10 6 17 -1 6 -6 8 -13 4 -17z m75 4 c-7
-7 -12 -8 -12 -2 0 14 12 26 19 19 2 -3 -1 -11 -7 -17z m4003 12 c3 -5 1 -10
-4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m85 1 c0 -6 -4 -13
-10 -16 -5 -3 -10 1 -10 9 0 9 5 16 10 16 6 0 10 -4 10 -9z m-4164 -68 c-10
-10 -19 5 -10 18 6 11 8 11 12 0 2 -7 1 -15 -2 -18z m94 17 c0 -5 -4 -10 -9
-10 -6 0 -13 5 -16 10 -3 6 1 10 9 10 9 0 16 -4 16 -10z m3980 0 c0 -5 -7 -10
-16 -10 -8 0 -12 5 -9 10 3 6 10 10 16 10 5 0 9 -4 9 -10z m80 -10 c0 -5 -4
-10 -9 -10 -6 0 -13 5 -16 10 -3 6 1 10 9 10 9 0 16 -4 16 -10z m-4106 -15 c3
-8 1 -15 -4 -15 -6 0 -10 7 -10 15 0 8 2 15 4 15 2 0 6 -7 10 -15z m4066 0 c0
-8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m-3980
-47 c0 -18 -2 -20 -9 -8 -6 8 -7 18 -5 22 9 14 14 9 14 -14z m3882 -10 c-9 -9
-12 -7 -12 12 0 19 3 21 12 12 9 -9 9 -15 0 -24z m-32 -9 c11 -19 10 -20 -10
-9 -11 6 -18 15 -15 21 9 13 13 11 25 -12z m-3880 -4 c10 -12 10 -15 -4 -15
-9 0 -16 7 -16 15 0 8 2 15 4 15 2 0 9 -7 16 -15z m90 6 c0 -5 -9 -11 -19 -14
-14 -3 -18 -1 -14 9 6 15 33 19 33 5z m3868 -3 c-3 -7 -13 -15 -24 -17 -16 -3
-17 -1 -5 13 16 19 34 21 29 4z m-3948 -46 c0 -13 -12 -22 -22 -16 -10 6 -1
24 13 24 5 0 9 -4 9 -8z m80 -2 c0 -5 -7 -7 -15 -4 -8 4 -15 8 -15 10 0 2 7 4
15 4 8 0 15 -4 15 -10z m3780 6 c0 -2 -7 -6 -15 -10 -8 -3 -15 -1 -15 4 0 6 7
10 15 10 8 0 15 -2 15 -4z m74 -17 c-6 -11 -24 -2 -24 11 0 5 7 7 15 4 8 -4
12 -10 9 -15z m-3897 -21 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z
m3845 0 c-7 -7 -12 -8 -12 -2 0 6 3 14 7 17 3 4 9 5 12 2 2 -3 -1 -11 -7 -17z
m-86 -35 c-10 -10 -19 5 -10 18 6 11 8 11 12 0 2 -7 1 -15 -2 -18z m-3666 7
c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m40
-25 c-7 -9 -15 -13 -18 -10 -3 2 1 11 8 20 7 9 15 13 18 10 3 -2 -1 -11 -8
-20z m3590 15 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10
-4 10 -10z m-3660 -20 c0 -5 -7 -7 -15 -4 -8 4 -15 8 -15 10 0 2 7 4 15 4 8 0
15 -4 15 -10z m3735 0 c-3 -5 -10 -10 -16 -10 -5 0 -9 5 -9 10 0 6 7 10 16 10
8 0 12 -4 9 -10z m-3639 -33 c4 -4 0 -7 -9 -7 -9 0 -18 4 -21 9 -7 10 20 9 30
-2z m3549 3 c-3 -5 -10 -10 -16 -10 -5 0 -9 5 -9 10 0 6 7 10 16 10 8 0 12 -4
9 -10z m-3638 -27 c-3 -3 -11 -2 -17 2 -9 6 -9 10 1 16 14 8 27 -7 16 -18z
m3713 6 c0 -5 -4 -9 -10 -9 -5 0 -10 7 -10 16 0 8 5 12 10 9 6 -3 10 -10 10
-16z m-3660 -20 c0 -5 -4 -9 -10 -9 -5 0 -10 7 -10 16 0 8 5 12 10 9 6 -3 10
-10 10 -16z m3610 5 c0 -8 -5 -12 -10 -9 -6 4 -8 11 -5 16 9 14 15 11 15 -7z
m-3520 -30 c0 -8 -4 -12 -10 -9 -5 3 -10 10 -10 16 0 5 5 9 10 9 6 0 10 -7 10
-16z m3440 7 c0 -6 -4 -13 -10 -16 -5 -3 -10 1 -10 9 0 9 5 16 10 16 6 0 10
-4 10 -9z m-3395 -20 c-3 -6 -11 -11 -17 -11 -6 0 -6 6 2 15 14 17 26 13 15
-4z m3345 3 c0 -8 -4 -12 -10 -9 -5 3 -10 10 -10 16 0 5 5 9 10 9 6 0 10 -7
10 -16z m-3425 -14 c3 -5 -1 -10 -10 -10 -9 0 -13 5 -10 10 3 6 8 10 10 10 2
0 7 -4 10 -10z m3505 0 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10
2 0 4 -4 4 -10z m-3400 -30 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10
15 10 8 0 15 -4 15 -10z m3310 0 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6
7 10 15 10 8 0 15 -4 15 -10z m-3390 -14 c0 -2 -7 -6 -15 -10 -8 -3 -15 -1
-15 4 0 6 7 10 15 10 8 0 15 -2 15 -4z m3414 -20 c-3 -9 -10 -13 -16 -10 -5 3
-4 12 3 20 15 18 22 13 13 -10z m46 15 c0 -17 -18 -21 -24 -6 -3 9 0 15 9 15
8 0 15 -4 15 -9z m-3420 -22 c0 -6 -4 -7 -10 -4 -5 3 -10 11 -10 16 0 6 5 7
10 4 6 -3 10 -11 10 -16z m90 -21 c0 -19 -2 -20 -10 -8 -13 19 -13 30 0 30 6
0 10 -10 10 -22z m3182 0 c-7 -7 -12 -8 -12 -2 0 6 3 14 7 17 3 4 9 5 12 2 2
-3 -1 -11 -7 -17z m-3131 -11 c-13 -13 -26 -3 -16 12 3 6 11 8 17 5 6 -4 6
-10 -1 -17z m3097 5 c2 -7 -2 -10 -12 -6 -9 3 -16 11 -16 16 0 13 23 5 28 -10z
m-3173 -22 c3 -5 -1 -10 -9 -10 -9 0 -16 5 -16 10 0 6 4 10 9 10 6 0 13 -4 16
-10z m3255 0 c0 -5 -7 -10 -16 -10 -8 0 -12 5 -9 10 3 6 10 10 16 10 5 0 9 -4
9 -10z m-3215 -10 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8
-4 11 -10z m3166 -12 c20 -24 24 -38 11 -38 -4 0 -12 10 -17 23 -11 23 -15 24
-52 12 -13 -3 -23 -2 -23 4 0 5 12 12 28 14 15 2 29 5 30 6 2 0 12 -9 23 -21z
m-3111 2 c0 -13 -11 -13 -30 0 -12 8 -11 10 8 10 12 0 22 -4 22 -10z m-84 -37
c-10 -10 -19 5 -10 18 6 11 8 11 12 0 2 -7 1 -15 -2 -18z m54 -5 c0 -5 -4 -8
-10 -8 -5 0 -10 10 -10 23 0 18 2 19 10 7 5 -8 10 -18 10 -22z m3110 2 c0 -5
-5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-3020 -16 c0
-8 -4 -12 -10 -9 -5 3 -10 10 -10 16 0 5 5 9 10 9 6 0 10 -7 10 -16z m2930 7
c0 -6 -4 -13 -10 -16 -5 -3 -10 1 -10 9 0 9 5 16 10 16 6 0 10 -4 10 -9z
m-2876 -16 c-6 -16 -24 -21 -24 -7 0 11 11 22 21 22 5 0 6 -7 3 -15z m2831 4
c11 -17 -1 -21 -15 -4 -8 9 -8 15 -2 15 6 0 14 -5 17 -11z m-2869 -33 c0 0 8
-13 17 -28 19 -31 21 -38 8 -38 -5 0 -14 14 -20 30 -8 24 -16 30 -40 30 -17 0
-31 5 -31 11 0 7 12 8 33 4 17 -4 32 -8 33 -9z m2944 4 c0 -5 -17 -9 -37 -9
-38 1 -38 1 -8 9 43 11 45 11 45 0z m-2885 -10 c3 -6 -4 -10 -17 -10 -18 0
-20 2 -8 10 19 12 18 12 25 0z m2805 -6 c0 -2 -9 -4 -20 -4 -11 0 -20 4 -20 9
0 5 9 7 20 4 11 -3 20 -7 20 -9z m-2880 -22 c0 -13 -12 -22 -22 -16 -10 6 -1
24 13 24 5 0 9 -4 9 -8z m2938 -10 c6 -18 -15 -14 -22 4 -4 10 -1 14 6 12 6
-2 14 -10 16 -16z m-48 0 c0 -13 -12 -22 -22 -16 -10 6 -1 24 13 24 5 0 9 -4
9 -8z m-2750 -18 c0 -8 -4 -12 -10 -9 -5 3 -10 10 -10 16 0 5 5 9 10 9 6 0 10
-7 10 -16z m46 -11 c-10 -10 -19 5 -10 18 6 11 8 11 12 0 2 -7 1 -15 -2 -18z
m2561 1 c-9 -9 -28 6 -21 18 4 6 10 6 17 -1 6 -6 8 -13 4 -17z m43 11 c-7 -9
-15 -13 -17 -11 -7 7 7 26 19 26 6 0 6 -6 -2 -15z m-2675 -35 c3 -5 -1 -10 -9
-10 -9 0 -16 5 -16 10 0 6 4 10 9 10 6 0 13 -4 16 -10z m2655 6 c0 -3 -4 -8
-10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m50 -6 c0 -5 -7
-10 -16 -10 -8 0 -12 5 -9 10 3 6 10 10 16 10 5 0 9 -4 9 -10z m-2600 -10 c0
-5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z m2505
0 c-3 -5 -10 -10 -16 -10 -5 0 -9 5 -9 10 0 6 7 10 16 10 8 0 12 -4 9 -10z
m65 -43 c0 -5 -4 -5 -10 -2 -5 3 -10 14 -10 23 0 15 2 15 10 2 5 -8 10 -19 10
-23z m-2650 7 c0 -8 -4 -12 -10 -9 -5 3 -10 10 -10 16 0 5 5 9 10 9 6 0 10 -7
10 -16z m56 -11 c-7 -7 -26 7 -26 19 0 6 6 6 15 -2 9 -7 13 -15 11 -17z m94 6
c0 -5 -4 -9 -10 -9 -5 0 -10 7 -10 16 0 8 5 12 10 9 6 -3 10 -10 10 -16z
m2454 6 c-7 -16 -24 -21 -24 -6 0 8 7 13 27 20 1 1 0 -6 -3 -14z m-2404 -4 c0
-6 -4 -13 -10 -16 -5 -3 -10 1 -10 9 0 9 5 16 10 16 6 0 10 -4 10 -9z m2264
-7 c5 -14 4 -15 -9 -4 -17 14 -19 20 -6 20 5 0 12 -7 15 -16z m34 -4 c-14 -22
-24 -19 -15 5 4 8 11 15 17 15 6 0 6 -7 -2 -20z m-2378 -30 c0 -5 -4 -10 -10
-10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m100 0 c0 -5 -4 -10
-10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m2215 0 c3 -5 -1
-10 -9 -10 -9 0 -16 5 -16 10 0 6 4 10 9 10 6 0 13 -4 16 -10z m100 0 c-3 -5
-10 -10 -16 -10 -5 0 -9 5 -9 10 0 6 7 10 16 10 8 0 12 -4 9 -10z m-2199 -56
c-8 -16 -20 -42 -26 -58 -6 -16 -13 -26 -16 -23 -3 3 6 31 21 62 14 31 28 55
31 53 2 -3 -2 -18 -10 -34z m1974 20 c0 -8 -4 -12 -10 -9 -5 3 -10 10 -10 16
0 5 5 9 10 9 6 0 10 -7 10 -16z m-2160 -4 c0 -5 -4 -10 -10 -10 -5 0 -10 5
-10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m55 0 c3 -5 2 -10 -4 -10 -5 0 -13 5
-16 10 -3 6 -2 10 4 10 5 0 13 -4 16 -10z m95 0 c0 -5 -7 -7 -15 -4 -8 4 -15
8 -15 10 0 2 7 4 15 4 8 0 15 -4 15 -10z m2055 0 c-3 -5 -11 -10 -16 -10 -6 0
-7 5 -4 10 3 6 11 10 16 10 6 0 7 -4 4 -10z m95 -5 c-7 -9 -15 -13 -17 -11 -7
7 7 26 19 26 6 0 6 -6 -2 -15z m50 6 c0 -6 -4 -13 -10 -16 -5 -3 -10 1 -10 9
0 9 5 16 10 16 6 0 10 -4 10 -9z m-2120 -31 c0 -5 -10 -10 -22 -9 -22 0 -22 1
-3 9 11 5 21 9 23 9 1 1 2 -3 2 -9z m1910 -6 c0 -2 -9 -4 -21 -4 -11 0 -18 4
-14 10 5 8 35 3 35 -6z m-2010 -4 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6
7 10 15 10 8 0 15 -4 15 -10z m2100 0 c0 -5 -6 -10 -14 -10 -8 0 -18 5 -21 10
-3 6 3 10 14 10 12 0 21 -4 21 -10z m-1876 -9 c3 -5 -1 -14 -8 -20 -12 -10
-13 -8 -9 8 6 23 9 25 17 12z m1656 -29 l0 -27 -14 25 c-13 26 -11 44 5 35 5
-4 9 -18 9 -33z m-1703 2 c-9 -9 -28 6 -21 18 4 6 10 6 17 -1 6 -6 8 -13 4
-17z m1743 17 c0 -6 -4 -13 -10 -16 -5 -3 -10 1 -10 9 0 9 5 16 10 16 6 0 10
-4 10 -9z m-1840 -22 c0 -5 -4 -9 -10 -9 -5 0 -10 7 -10 16 0 8 5 12 10 9 6
-3 10 -10 10 -16z m1934 6 c-7 -16 -24 -21 -24 -6 0 8 7 13 27 20 1 1 0 -6 -3
-14z m46 -6 c0 -5 -4 -9 -10 -9 -5 0 -10 7 -10 16 0 8 5 12 10 9 6 -3 10 -10
10 -16z m-1800 -9 c0 -5 -7 -10 -16 -10 -8 0 -12 5 -9 10 3 6 10 10 16 10 5 0
9 -4 9 -10z m1600 -14 c0 -2 -11 -1 -25 2 -14 2 -22 8 -19 13 4 8 44 -6 44
-15z m-1476 5 c3 -5 -1 -14 -8 -20 -12 -10 -13 -8 -9 8 6 23 9 25 17 12z
m1326 -7 c0 -8 -4 -12 -10 -9 -5 3 -10 10 -10 16 0 5 5 9 10 9 6 0 10 -7 10
-16z m-1540 2 c0 -9 -30 -14 -35 -6 -4 6 3 10 14 10 12 0 21 -2 21 -4z m1770
-6 c0 -5 -4 -10 -9 -10 -6 0 -13 5 -16 10 -3 6 1 10 9 10 9 0 16 -4 16 -10z
m-1600 -10 c0 -5 -4 -10 -9 -10 -6 0 -13 5 -16 10 -3 6 1 10 9 10 9 0 16 -4
16 -10z m1420 6 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0
10 -2 10 -4z m140 -38 c0 -20 -18 -5 -23 19 -5 26 -4 26 9 8 8 -10 14 -22 14
-27z m-1710 7 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10
-7 10 -15z m60 5 c0 -5 -4 -10 -9 -10 -6 0 -13 5 -16 10 -3 6 1 10 9 10 9 0
16 -4 16 -10z m170 0 c0 -5 -7 -10 -16 -10 -8 0 -12 5 -9 10 3 6 10 10 16 10
5 0 9 -4 9 -10z m126 -17 c-10 -10 -19 5 -10 18 6 11 8 11 12 0 2 -7 1 -15 -2
-18z m1004 6 c0 -5 -4 -9 -10 -9 -5 0 -10 7 -10 16 0 8 5 12 10 9 6 -3 10 -10
10 -16z m125 11 c3 -5 -1 -10 -9 -10 -9 0 -16 5 -16 10 0 6 4 10 9 10 6 0 13
-4 16 -10z m180 0 c-3 -5 -10 -10 -16 -10 -5 0 -9 5 -9 10 0 6 7 10 16 10 8 0
12 -4 9 -10z m-1490 -9 c-17 -17 -65 -24 -65 -11 0 7 6 10 13 7 6 -2 21 -1 32
4 27 11 31 11 20 0z m135 -11 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5
10 10 10 6 0 10 -4 10 -10z m1095 0 c-3 -5 -11 -10 -16 -10 -6 0 -7 5 -4 10 3
6 11 10 16 10 6 0 7 -4 4 -10z m185 1 c0 -5 -16 -9 -35 -8 -46 3 -46 17 0 17
19 0 35 -4 35 -9z m-1066 -16 c-4 -8 -8 -15 -10 -15 -2 0 -4 7 -4 15 0 8 4 15
10 15 5 0 7 -7 4 -15z m666 -1 c0 -8 -4 -12 -10 -9 -5 3 -10 10 -10 16 0 5 5
9 10 9 6 0 10 -7 10 -16z m130 -5 c0 -5 -4 -9 -10 -9 -5 0 -10 7 -10 16 0 8 5
12 10 9 6 -3 10 -10 10 -16z m-1150 -9 c0 -11 -7 -20 -15 -20 -13 0 -13 3 -3
20 7 11 14 20 15 20 2 0 3 -9 3 -20z m230 16 c0 -3 -4 -8 -10 -11 -5 -3 -10
-1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m960 -11 c0 -8 -4 -15 -10 -15 -5 0
-7 7 -4 15 4 8 8 15 10 15 2 0 4 -7 4 -15z m132 -7 c-6 -6 -15 -8 -19 -4 -4 4
-1 11 7 16 19 12 27 3 12 -12z m-1272 2 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10
-3 6 -1 10 4 10 6 0 11 -4 11 -10z m255 0 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11
10 0 6 2 10 4 10 3 0 8 -4 11 -10z m215 -6 c0 -8 -4 -12 -10 -9 -5 3 -10 10
-10 16 0 5 5 9 10 9 6 0 10 -7 10 -16z m170 2 c0 -8 -4 -18 -10 -21 -5 -3 -10
3 -10 14 0 12 5 21 10 21 6 0 10 -6 10 -14z m170 10 c0 -3 -4 -8 -10 -11 -5
-3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m210 -6 c0 -5 -7 -10 -16
-10 -8 0 -12 5 -9 10 3 6 10 10 16 10 5 0 9 -4 9 -10z m-680 -10 c0 -5 -5 -10
-11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m595 0 c3 -5 -1 -10
-9 -10 -9 0 -16 5 -16 10 0 6 4 10 9 10 6 0 13 -4 16 -10z m385 0 c0 -5 -5
-10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-1237 -7 c-7 -2
-19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m342 -3 c3 -5 2 -10 -4 -10 -5
0 -13 5 -16 10 -3 6 -2 10 4 10 5 0 13 -4 16 -10z m84 -11 c-16 -10 -23 -4
-14 10 3 6 11 8 17 5 6 -4 5 -9 -3 -15z m86 -7 c-8 -8 -25 10 -19 20 4 6 9 5
15 -3 4 -7 6 -15 4 -17z m81 1 c-10 -10 -19 5 -10 18 6 11 8 11 12 0 2 -7 1
-15 -2 -18z m94 7 c0 -5 -7 -7 -15 -4 -8 4 -12 10 -9 15 6 11 24 2 24 -11z
m80 10 c0 -5 -7 -10 -16 -10 -8 0 -12 5 -9 10 3 6 10 10 16 10 5 0 9 -4 9 -10z
m350 6 c0 -11 -19 -15 -25 -6 -3 5 1 10 9 10 9 0 16 -2 16 -4z m-1035 -16 c3
-5 2 -10 -4 -10 -5 0 -13 5 -16 10 -3 6 -2 10 4 10 5 0 13 -4 16 -10z m945 0
c0 -5 -7 -10 -16 -10 -8 0 -12 5 -9 10 3 6 10 10 16 10 5 0 9 -4 9 -10z m40
-16 c0 -8 -5 -12 -10 -9 -6 4 -8 11 -5 16 9 14 15 11 15 -7z m-1030 -4 c0 -5
-4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m140 6 c0
-2 -7 -6 -15 -10 -8 -3 -15 -1 -15 4 0 6 7 10 15 10 8 0 15 -2 15 -4z m770 0
c0 -11 -19 -15 -25 -6 -3 5 1 10 9 10 9 0 16 -2 16 -4z m-690 -16 c0 -5 -4
-10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m79 -11 c-16
-10 -23 -4 -14 10 3 6 11 8 17 5 6 -4 5 -9 -3 -15z m436 11 c3 -5 -1 -10 -9
-10 -8 0 -18 5 -21 10 -3 6 1 10 9 10 8 0 18 -4 21 -10z m80 0 c-3 -5 -10 -10
-16 -10 -5 0 -9 5 -9 10 0 6 7 10 16 10 8 0 12 -4 9 -10z m-645 -21 c0 -5 -4
-9 -10 -9 -5 0 -10 7 -10 16 0 8 5 12 10 9 6 -3 10 -10 10 -16z m225 11 c3 -5
-1 -10 -9 -10 -9 0 -16 5 -16 10 0 6 4 10 9 10 6 0 13 -4 16 -10z m80 0 c-3
-5 -12 -10 -18 -10 -7 0 -6 4 3 10 19 12 23 12 15 0z m90 0 c3 -5 -1 -10 -9
-10 -9 0 -16 5 -16 10 0 6 4 10 9 10 6 0 13 -4 16 -10z m80 0 c-3 -5 -10 -10
-16 -10 -5 0 -9 5 -9 10 0 6 7 10 16 10 8 0 12 -4 9 -10z m215 -5 c0 -8 -4
-15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m-353 -27
c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m-167 6 c0 -8 -4 -12
-10 -9 -5 3 -10 10 -10 16 0 5 5 9 10 9 6 0 10 -7 10 -16z m350 7 c0 -6 -4
-13 -10 -16 -5 -3 -10 1 -10 9 0 9 5 16 10 16 6 0 10 -4 10 -9z"/>
<path d="M3316 4512 c-3 -3 -6 -50 -6 -104 0 -97 1 -99 22 -96 12 2 28 14 35
28 15 29 17 108 4 143 -9 23 -43 41 -55 29z"/>
<path d="M3517 4418 c-6 -23 12 -43 25 -30 9 9 -1 52 -12 52 -4 0 -10 -10 -13
-22z"/>
<path d="M3902 4420 c-12 -29 1 -51 20 -32 8 8 8 18 1 34 l-11 23 -10 -25z"/>
<path d="M4626 4513 c-3 -3 -6 -24 -6 -45 0 -27 4 -38 15 -38 11 0 15 11 15
39 0 38 -10 57 -24 44z"/>
<path d="M4620 4359 c0 -43 3 -50 18 -47 22 4 30 77 10 89 -24 16 -28 9 -28
-42z"/>
				</g>
			</svg>
		</Box>
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
